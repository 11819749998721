import React, { Component } from "react";
import Typical from "react-typical";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';


const list = {
  items: [
    {
      primaryText: 'About',
      rightAvatar: <Avatar sx={{ bgcolor: blue[500] }}><InfoIcon size="large" /></Avatar>,
      link: '#about'
    },
    {
      primaryText: 'Projects',
      rightAvatar: <Avatar sx={{ bgcolor: blue[500] }}><WorkIcon size="large" /></Avatar>,
      link: '#dev-projects'
    },
    {
      primaryText: 'Skills',
      rightAvatar: <Avatar sx={{ bgcolor: blue[500] }}><ArchitectureIcon size="large" /></Avatar>,
      link: '#skills'
    },
    {
      primaryText: 'Experience',
      rightAvatar: <Avatar sx={{ bgcolor: blue[500] }}><SchoolIcon size="large" /></Avatar>,
      link: '#resume'
    },
    {
      primaryText: 'Contact',
      rightAvatar: <Avatar sx={{ bgcolor: blue[500] }}><AttachEmailIcon size="large" /></Avatar>,
      link: '#contact'
    },
  ],
};

class Header extends Component {
  titles = [];


  constructor() {
    super();
    this.state = { checked: false };
    this.onThemeSwitchChange = this.onThemeSwitchChange.bind(this);
    var dataThemeAttribute = "data-theme";
    var body = document.body;
    var newTheme = "dark";
    body.setAttribute(dataThemeAttribute, newTheme);
  }

  onThemeSwitchChange(checked) {
    this.setState({ checked });
    this.setTheme();
  }

  setTheme() {

  }

  render() {
    if (this.props.sharedData) {
      var name = this.props.sharedData.name;
      this.titles = this.props.sharedData.titles.map(x => [x.toUpperCase(), 1500]).flat();
    }

    const HeaderTitleTypeAnimation = React.memo(() => {
      return <Typical className="title-styles" steps={this.titles} loop={50} />
    }, (props, prevProp) => true);

    return (
      <header id="home" style={{ height: window.innerHeight - 140, display: 'block' }}>
        {/* <nav class="navMenu">
          <a href="#">Home</a>
          <a href="#about">About</a>
          <a href="#dev-projects">Dev Projects</a>
          <a href="#ui-projects">UI/UX Projects</a>
          <a href="#contact">Contact</a>
          <div class="dot"></div>
        </nav> */}

        <nav id="site-nav">
          <AnchorLink href="#about">ABOUT</AnchorLink>
          <AnchorLink href="#dev-projects">PROJECTS</AnchorLink>
          <AnchorLink href="#skills">SKILLS</AnchorLink>
          <AnchorLink href="#resume">EXPERIENCE</AnchorLink>
          {/* <AnchorLink href="#contact">CONTACT</AnchorLink> */}
          <div id="indicator"></div>
        </nav>





        <div className="row aligner" style={{ height: '100%' }}>
          <div className="col-md-12">
            <div>
              <span className="iconify header-icon" data-icon="la:laptop-code" data-inline="false"></span>
              <br />
              <h1 className="mb-0">
                <Typical steps={[name]} wrapper="p" />
              </h1>
              <div className="title-container">
                <HeaderTitleTypeAnimation />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
