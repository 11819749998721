import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import DevProjects from "./components/DevProjects";
import UIUXProjects from "./components/UIUXProjects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ScrollToTop from "react-scroll-to-top";
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';

const actions = [
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

const App = () => {

  const [resumeData, setResumeData] = useState({});
  const [sharedData, setSharedData] = useState({});

  const applyPickedLanguage = (pickedLanguage, oppositeLangIconId) => {
    swapCurrentlyActiveLanguage(oppositeLangIconId);
    document.documentElement.lang = pickedLanguage;
    var resumePath = `res_primaryLanguage.json`;
    loadResumeFromPath(resumePath);
  }

  const swapCurrentlyActiveLanguage = (oppositeLangIconId) => {
    var pickedLangIconId =
      oppositeLangIconId === window.$primaryLanguageIconId
        ? window.$secondaryLanguageIconId
        : window.$primaryLanguageIconId;
  }

  useEffect(() => {
    loadSharedData();
    applyPickedLanguage(window.$primaryLanguage, window.$secondaryLanguageIconId)
  }, []);

  const loadResumeFromPath = (path) => {
    $.ajax({
      url: path,
      dataType: "json",
      cache: false,
      success: function (data) {
        setResumeData(data);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  const loadSharedData = () => {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: false,
      success: function (data) {
        setSharedData(data);
      }.bind(this),
      error: function (xhr, status, err) {
        alert(err);
      },
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <ScrollToTop smooth style={{ 'z-index': '100', 'border-radius': '50%' }} />
        <Header sharedData={sharedData.basic_info} />
        <About
          resumeBasicInfo={resumeData.basic_info}
          sharedBasicInfo={sharedData.basic_info}
        />
        <DevProjects
          resumeProjects={resumeData.dev_projects}
          resumeBasicInfo={resumeData.basic_info}
        />
        <UIUXProjects
          resumeProjects={resumeData.uiux_projects}
          resumeBasicInfo={resumeData.basic_info}
        />
        <Skills
          sharedSkills={sharedData.skills}
          resumeBasicInfo={resumeData.basic_info}
        />
        <Experience
          resumeExperience={resumeData.experience}
          resumeBasicInfo={resumeData.basic_info}
        />
        {/* <Contact /> */}
        <Footer sharedBasicInfo={sharedData.basic_info} />
      </div>
    </ThemeProvider>
  );

}

export default App;
